import { useAuth } from '@guider-global/auth-hooks';
import { getOrigin } from '@guider-global/front-end-utils';
import { buildSanityImageUrl } from '@guider-global/sanity-hooks';
import {
  OrganizationContainer,
  OrganizationContentContainer,
  BaseHeader,
  BaseLoadingButton,
} from '@guider-global/ui';
import { Box } from '@mui/material';
import CompleteProfileForm from 'forms/ProfileForms/CompleteProfileForm';
import useCompleteProfileForm from 'forms/ProfileForms/CompleteProfileForm/hooks/useCompleteProfileForm';
import { useMixpanelEvents } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';
import { getIncompleteProfileFields } from 'utils';

const DATA_CY_PAGE = 'complete_profile';
const TIMEOUT = 5000;

export const CompleteProfilePage = () => {
  const navigate = useNavigate();
  const { resetUserIdentity } = useMixpanelEvents();
  // Auth
  const { logout } = useAuth({ waitForAuthentication: true });
  // Redux
  const dispatch = useAppDispatch();
  // Hooks
  const {
    // React Hook Form
    control,
    handleSubmit,
    errors,
    unregister,
    // Sanity Content
    sanityBaseLanguage,
    // Profile
    profile,
    // Organization
    sanityOrganization,
    sanityOrganizationProfileFields,
    sanityOrganizationPersonalDetails,
    organizationLogo,
    organizationName,
    additionalAgreements,
    organizationAgreements,
    // Effects
    loading,
    success,
    updateSuccess,
    clearSuccess,
    // Events
    onSubmit,
    onError,
    // Errors/Validation
    formErrors,
    isErrors,
    clearIsErrors,
    isDirty,
  } = useCompleteProfileForm();
  const sanityBaseLanguageSettings = sanityBaseLanguage.settings;
  const sanityBaseLanguageOnboarding = sanityBaseLanguage.onboarding;

  const incompleteProfile = useMemo(
    () =>
      getIncompleteProfileFields({
        profile,
        sanityOrganization,
        organizationAgreements: additionalAgreements,
      }),
    [additionalAgreements, profile, sanityOrganization],
  );

  const imageSource = organizationLogo
    ? buildSanityImageUrl({
        source: organizationLogo,
        format: 'png',
        width: 320,
      })
    : undefined;

  const newProfileFields = sanityBaseLanguageOnboarding?.new_profile_fields;

  const successAlertText =
    sanityBaseLanguageSettings.profile.profile_details.success_alert_text;
  const serverAlertText =
    sanityBaseLanguageSettings.profile.profile_details.server_error_alert_text;

  useEffect(() => {
    if (incompleteProfile.length === 0) {
      navigate('/dashboard');
    }
  }, [incompleteProfile.length, navigate]);

  useEffect(() => {
    if (isErrors) {
      console.error(formErrors);
      dispatch(
        showAppAlert({
          severity: 'error',
          message: serverAlertText,
          timeout: TIMEOUT,
        }),
      );
    }
    const clearErrorState = setTimeout(() => {
      clearIsErrors();
    }, TIMEOUT);

    return () => clearTimeout(clearErrorState);
  }, [isErrors, formErrors, dispatch, serverAlertText, clearIsErrors]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(
        showAppAlert({
          severity: 'success',
          message: successAlertText,
          timeout: TIMEOUT,
        }),
      );
      const clearSuccessState = setTimeout(() => {
        clearSuccess();
      }, TIMEOUT);

      return () => clearTimeout(clearSuccessState);
    }
  }, [clearSuccess, dispatch, successAlertText, updateSuccess]);

  // Events
  const handleLogout = async () => {
    await logout({ logoutParams: { returnTo: getOrigin() } });
    resetUserIdentity();
  };

  return (
    <OrganizationContainer imageSource={imageSource}>
      <>
        <OrganizationContentContainer sx={{ width: ['100%', '450px'] }}>
          <BaseHeader
            data-cy={DATA_CY_PAGE}
            header={newProfileFields?.title}
            subheader={newProfileFields?.description}
            subheaderProps={{ whiteSpace: 'pre-wrap' }}
          />
          <Box
            sx={{ display: 'flex', flexFlow: 'column nowrap' }}
            component="form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <CompleteProfileForm
              control={control}
              errors={errors}
              unregister={unregister}
              loading={!success || loading}
              organizationName={organizationName}
              sanityBaseLanguage={sanityBaseLanguage}
              profile={profile}
              sanityOrganizationProfileFields={sanityOrganizationProfileFields}
              sanityOrganizationPersonalDetails={
                sanityOrganizationPersonalDetails
              }
              organizationAgreements={organizationAgreements}
            />
            <Box
              sx={{
                width: '100%',
              }}
            >
              <BaseLoadingButton
                data-cy={`${DATA_CY_PAGE}-submit-button`}
                fullWidth
                disableElevation
                disabled={!isDirty}
                loading={loading}
                size="large"
                sx={{ mt: 2 }}
                type="submit"
              >
                {newProfileFields.submit_button_label}
              </BaseLoadingButton>
            </Box>
          </Box>
        </OrganizationContentContainer>
        <BaseLoadingButton
          variant="text"
          data-cy={`${DATA_CY_PAGE}-decline-button`}
          sx={{ mt: 3 }}
          onClick={handleLogout}
        >
          {newProfileFields.logout_button_label}
        </BaseLoadingButton>
      </>
    </OrganizationContainer>
  );
};
