// external
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { Text, theme } from '@guider-global/ui';
import { Box as MuiBox, Stack, ThemeProvider, useTheme } from '@mui/material';
import { Background, PageWrapper } from 'components';
import { useLocalization, useProfiles } from 'hooks';
import {
  ProgramMembershipsContainer,
  DashboardResourcesContainer,
} from 'containers/dashboard';
import { deepMerge } from 'utils';
import { getSubDomain } from '@guider-global/front-end-utils';
import { DashboardNotificationsContainer } from 'containers';

export const DashboardPage: React.FunctionComponent = () => {
  const organizationTheme = useTheme();

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { getBaseLanguage } = useSanityBaseLanguage({
    getSilently: true,
    localeCode: localeCode,
  });
  const baseLanguage = getBaseLanguage();

  useProfiles({
    getSilently: true,
  });

  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );
  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
      <Background />
      <PageWrapper>
        <MuiBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: {
              xs: '100%',
              md: '80%',
              maxWidth: '1440px',
            },
            my: 2,
            rowGap: 6,
            position: 'relative',
          }}
        >
          <Stack direction="row" alignItems="center">
            <Text
              variant="h1"
              text={baseLanguage.dashboard.title}
              textAlign="left"
              sx={{
                pl: {
                  xs: 2,
                  md: 0,
                },
              }}
              color="#FFFFFF"
            />
          </Stack>
          <DashboardNotificationsContainer />
          <ProgramMembershipsContainer />
          <DashboardResourcesContainer />
        </MuiBox>
      </PageWrapper>
    </ThemeProvider>
  );
};
