/* eslint-disable camelcase */

// external
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, useScrollTrigger } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@guider-global/auth-hooks';

// components
import {
  LandingPageContent,
  ProgramLandingPageHero,
} from 'components/LandingPage';
import { AnimationWrapper } from 'components/AnimationWrapper';

// store
import {
  selectRegistrationType,
  setNavbarUnauthenticatedActions,
  showNavbar,
} from 'store/slices/appSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

// types
import { INavbarLink } from 'components/Navbar';
import { ICustomField, URLQueryParams } from '@guider-global/shared-types';
import { useSanityOrganizationPrograms } from '@guider-global/sanity-hooks';
import {
  useMemberships,
  useMixpanelEvents,
  useMobileMediaQuery,
  useProfiles,
  useTrackEvent,
} from 'hooks';
import {
  getSubDomain,
  getAuthorizedProgramsList,
} from '@guider-global/front-end-utils';

export const ProgramLandingPage: React.FC = () => {
  const organizationSlug = getSubDomain();
  // Auth
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth({
    waitForAuthentication: true,
  });
  // Router
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  // Hooks
  const { programSlug = '' } = useParams<{
    programSlug: string;
  }>();

  // Tracker
  const { trackMixpanelEvent } = useMixpanelEvents({});
  useTrackEvent({
    track: {
      eventKey: 'program-landing-page-viewed',
      eventParams: {
        organization: { organizationSlug },
        program: { organizationSlug, programSlug },
      },
    },
    if: isAuthenticated,
  });

  const { getProgram, isLoadingSanityPrograms } = useSanityOrganizationPrograms(
    {},
  );
  const program = getProgram(programSlug);

  const programLandingPage = program?.landing_page;
  const isUnauthorizedAccessAllowed =
    programLandingPage?.unauthorized_access_allowed;

  const programDetails = program?.program_details ?? '';
  const guideCta =
    programLandingPage?.hero?.guide_cta?.cta_button_label ?? 'Be a Mentor';
  const traineeCta =
    programLandingPage?.hero?.trainee_cta?.cta_button_label ?? 'Find a Mentor';

  // Profiles
  const { getProfiles, isLoadingProfiles } = useProfiles({});
  const [profile] = getProfiles();
  const profileOrganizationFields = profile?.organizationFields as
    | ICustomField[]
    | undefined;

  // Memberships
  const { memberships: getMemberships, isLoadingMemberships } = useMemberships(
    {},
  );
  const memberships = getMemberships();
  const usersProgramSlugs = memberships.map((membership) => {
    return membership.programSlug;
  });

  const isLoading =
    isLoadingAuth ||
    isLoadingSanityPrograms() ||
    isLoadingProfiles() ||
    isLoadingMemberships();
  // Redux
  const dispatch = useAppDispatch();
  const registrationPageType = useAppSelector(selectRegistrationType);
  const showLoginButtons = registrationPageType !== 'none';

  // Styling
  const isMobile = useMobileMediaQuery();
  const [heroHeight, setHeroHeight] = useState(0);

  const handleRegister = useCallback(
    (redirectUrl?: string) => {
      const to = redirectUrl
        ? `/register/account?${URLQueryParams.REDIRECT}=${redirectUrl}`
        : '/register/account';
      navigate(to);
    },
    [navigate],
  );
  const handleGuideRegister = useCallback(() => {
    navigate(`join/guide`);
    trackMixpanelEvent('Navigation - Be a Guide');
  }, [navigate, trackMixpanelEvent]);
  const handleTraineeRegister = useCallback(() => {
    navigate(`join/trainee`);
    trackMixpanelEvent('Navigation - Be a Trainee');
  }, [navigate, trackMixpanelEvent]);

  const handleProgramButtons = useCallback(
    (isGuide?: boolean, pathname?: string) => {
      if (!isAuthenticated) {
        const removeTrailingSlash = pathname?.endsWith('/')
          ? pathname.slice(0, -1)
          : pathname;
        const redirectURL = isGuide
          ? `${removeTrailingSlash}/join/guide`
          : `${removeTrailingSlash}/join/trainee`;
        handleRegister(redirectURL);
        return;
      }
      if (isGuide) {
        handleGuideRegister();
        return;
      }
      handleTraineeRegister();
    },
    [
      handleGuideRegister,
      handleRegister,
      handleTraineeRegister,
      isAuthenticated,
    ],
  );

  useEffect(() => {
    dispatch(showNavbar(true));
    const programUnauthenticatedActions: INavbarLink[] = [
      {
        label: guideCta,
        action: () => handleProgramButtons(true, pathname),
        type: 'button',
        variant: 'contained',
        color: 'secondary',
        textColor: 'white',
      },
      {
        label: traineeCta,
        action: () => handleProgramButtons(false, pathname),
        type: 'button',
        variant: 'contained',
        color: 'info',
      },
    ];

    dispatch(setNavbarUnauthenticatedActions(programUnauthenticatedActions));
  }, [
    dispatch,
    handleRegister,
    guideCta,
    traineeCta,
    handleProgramButtons,
    pathname,
  ]);

  const userMemberOfProgram = usersProgramSlugs.includes(
    program?.metadata.id.current ?? '',
  );

  const authorizedPrograms =
    program && profileOrganizationFields
      ? getAuthorizedProgramsList({
          programs: [program],
          profileOrganizationFields,
        })
      : [];

  const userAuthorizedForProgram = userMemberOfProgram
    ? program
    : authorizedPrograms.find(
        (authorizedProgram) =>
          authorizedProgram.metadata.id.current ===
          program?.metadata.id.current,
      );

  useEffect(() => {
    if (!isLoading && isAuthenticated && !userAuthorizedForProgram && program) {
      navigate('/programs');
      return;
    }
    if (
      program &&
      !isLoading &&
      !isUnauthorizedAccessAllowed &&
      !isAuthenticated
    ) {
      navigate('/');
    }
  }, [
    isAuthenticated,
    isLoading,
    isUnauthorizedAccessAllowed,
    navigate,
    program,
    userAuthorizedForProgram,
  ]);

  const heroConfig = useMemo(
    () => programLandingPage?.hero,
    [programLandingPage],
  );

  const bottomButtonTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: heroHeight,
  });

  const shouldShowBottomButton = isMobile && bottomButtonTrigger;

  if (!isUnauthorizedAccessAllowed && !userAuthorizedForProgram && program) {
    return null;
  }

  if (!program) {
    return <></>;
  }

  return (
    <Box sx={{ backgroundColor: '#ffffff' }}>
      {heroConfig && programDetails && (
        <ProgramLandingPageHero
          config={heroConfig}
          programDetails={programDetails}
          setHeroHeight={setHeroHeight}
          onClickProgramButtons={handleProgramButtons}
        />
      )}
      {programLandingPage?.content && (
        <LandingPageContent
          config={programLandingPage.content}
          onClickProgramButtons={handleProgramButtons}
          onClickRegisterButton={handleRegister}
        />
      )}
      {isMobile && heroConfig && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'sticky',
            bottom: '0',
            zIndex: 10,
          }}
        >
          <AnimationWrapper
            externalTrigger={shouldShowBottomButton}
            slideDirection="up"
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, .3)',
              backdropFilter: 'blur(10px)',
              p: 2,
            }}
          >
            {showLoginButtons && (
              <>
                <Button
                  data-cy="pages_LandingPage_ProgramLandingPage_guide-register-button"
                  variant="contained"
                  color="secondary"
                  sx={{ width: '500px', maxWidth: '48%', color: 'white' }}
                  onClick={() => handleProgramButtons(true, pathname)}
                >
                  {heroConfig?.guide_cta?.cta_button_label}
                </Button>
                <Button
                  data-cy="pages_LandingPage_ProgramLandingPage_trainee-register-button"
                  variant="contained"
                  color="info"
                  sx={{ width: '500px', maxWidth: '48%' }}
                  onClick={() => handleProgramButtons(true, pathname)}
                >
                  {heroConfig?.trainee_cta?.cta_button_label}
                </Button>
              </>
            )}
          </AnimationWrapper>
        </Box>
      )}
    </Box>
  );
};
