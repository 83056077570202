// components
import { PageContent, PageWrapper } from 'components';

import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { useNavigate } from 'react-router-dom';
import { MdFlag } from 'react-icons/md';
import { useTheme } from '@mui/system';

export const GoalsPage = () => {
  // Base Language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const { palette } = useTheme();

  const navigate = useNavigate();

  const {
    goals_have_moved_title: title,
    goals_have_moved_description: description,
  } = baseLanguage.goals;

  return (
    <>
      <PageWrapper
        sx={{
          minHeight: '500px',
        }}
      >
        <PageContent
          title={title}
          subtitle={description}
          containerStylings={{
            gap: 1,
            width: { xs: '95%', md: '40%' },
            padding: 5,
            maxWidth: '1440px',
          }}
          icon={MdFlag}
          iconColor={palette.warning.main}
          actions={[
            {
              label: baseLanguage.globals.relationships.view_relationships,
              action: () => {
                navigate('/relationships');
              },
              color: 'info',
            },
          ]}
        />
      </PageWrapper>
    </>
  );
};
