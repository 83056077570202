// internal
import React, { useEffect } from 'react';
import { useAuth } from '@guider-global/auth-hooks';

// store
import { selectRegistrationType, showNavbar } from 'store/slices/appSlice';
import { OrganizationLandingPage } from 'pages/LandingPage/OrganizationLandingPage';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useSanityOrganization } from '@guider-global/sanity-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';

export const HomePage = () => {
  const dispatch = useAppDispatch();
  const organizationSlug = getSubDomain();

  const registrationPageType = useAppSelector(selectRegistrationType);

  const { getOrganization, isSuccessSanityOrganization } =
    useSanityOrganization({ organizationSlug, getSilently: false });
  const sanityOrganization = getOrganization();
  const isSanityOrganizationSuccess = isSuccessSanityOrganization();
  const isLandingPageEnabled =
    sanityOrganization.landing_page.enable_landing_page;

  // Route
  const navigate = useNavigate();

  const { isLoading, isAuthenticated } = useAuth({});

  useEffect(() => {
    if (isLandingPageEnabled) {
      dispatch(showNavbar(true));
    }
    if (
      !isLandingPageEnabled &&
      !isAuthenticated &&
      !isLoading &&
      isSanityOrganizationSuccess
    ) {
      if (
        registrationPageType === 'localAccounts' ||
        registrationPageType === 'localAccountsAndSSO'
      ) {
        navigate('/register/account');
      } else {
        navigate('/login');
      }
    }
    if (isAuthenticated && !isLoading) {
      navigate('/dashboard');
    }
  }, [
    dispatch,
    isAuthenticated,
    isLandingPageEnabled,
    isLoading,
    isSanityOrganizationSuccess,
    navigate,
    registrationPageType,
  ]);

  if (isLoading) return null;

  return isAuthenticated ? null : <OrganizationLandingPage />;
};
